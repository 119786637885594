import * as React from 'react'
import { graphql } from 'gatsby'
import TabPanel from '../components/tabs'
import { myContext } from '../../provider'
import { PrimaryArticle, SecondaryArticle, AllArticle } from '../components/articles/index-articles'
import { StaticImage } from "gatsby-plugin-image"

import portraitImage from '../images/landing.png'

const Index = ({ data }) => {
    console.log("Index data : ", data);
    return (
            <myContext.Consumer>
                {context => (
                    <>
                    <div className='sm:px-8 mt-16 sm:mt-32 h-full'>
                      <div className='mx-auto max-w-7xl lg:px-8'>
                        <div className='relative px-4 sm:px-8 lg:px-12'>
                          <div className='mx-auto max-w-2xl lg:max-w-5xl'>
                          <div className="mt-16 sm:mt-32">
                              <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                                <div className="lg:pl-20">
                                  <div className="max-w-xs px-2.5 lg:max-w-none">
                                    <img
                                      src={portraitImage}
                                      alt=""
                                      sizes="(min-width: 1024px) 32rem, 20rem"
                                      className="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800"
                                    />
                                  </div>
                                </div>
                                <div className="lg:order-first lg:row-span-2">
                                  <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                  ELF Media is a boutique brand and product development agency. 
                                  </h1>
                                  <div className="mt-6 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                                    <p>
                                    We focus on developing software applications for niche audiences. Our main target is fellow entrepreneurs seeking out like-minded professionals to help bring their dreams come to life.
                                    </p>
                                    <p>
                                    At ELF Media Product Development, we believe that every business has the potential to create something truly amazing. Our team is here to help you make that potential a reality. Whether you re starting from scratch or looking to improve an existing product, we can help. Let s work together to bring your vision to life.
                                    </p>
                                    <p>
                                    If you have any questions about ELF Media or our services, we would love to hear from you! Our team is dedicated to providing high-quality media solutions for businesses and organizations. Whether you re looking for help with a new website, a marketing campaign, or any other media-related project, we re here to help. Contact us today and let us know how we can assist you. We look forward to hearing from you!
                                    </p>
                                  </div>
                                </div>
                                <div className="lg:pl-20">
                                  <ul role="list">
                                    {/* <SocialLink
                                      href="mailto:spencer@planetaria.tech"
                                      icon={MailIcon}
                                      className="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40"
                                    >
                                      info@elfmedia.co
                                    </SocialLink> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    </>
                )}
            </myContext.Consumer>
    )
}

export const queryPrimary = graphql`
query {
  gogobackend {
      primary: news_articles_content(
        status: "Published"
        page_location: "primary"
        limit: 1
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
    }
      secondary: news_articles_content(
        status: "Published"
        page_location: "secondary"
        limit: 2
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
    }
      all: news_articles_content(
        status: "Published"
        limit: 3
        orderBy: [{ column: CREATED_AT, order: DESC }]
          
        ) {
          news_articles_id
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          author_slug
          image,
          created_at
      }
    }
  }
`

export default Index